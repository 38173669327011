import * as validators from '@vuelidate/validators'
import type { NuxtI18nInstance } from '@nuxtjs/i18n'
import messages from './messages.json'

export function createRequired(i18n: NuxtI18nInstance) {
  const { createI18nMessage } = validators
  // TODO: add dynamic values for validation function that can be passed from component
  // It works™
  // @ts-expect-error TODO: fix i18n types
  const withI18nMessage = createI18nMessage({ t: i18n.t.bind(i18n) })

  return {
    required: withI18nMessage(validators.required, {
      messagePath: () => messages['validations.required'],
    }),
    email: withI18nMessage(validators.email, {
      messagePath: () => messages['validations.email'],
    }),
    minLength: withI18nMessage(validators.minLength(2), {
      messagePath: () => messages['validations.minLength'],
      withArguments: true,
    }),
    checkPhoneLength: withI18nMessage(validators.minLength(16), {
      messagePath: () => messages['validations.checkPhone'],
      withArguments: true,
    }),
    maxLength: withI18nMessage(validators.maxLength(255), {
      messagePath: () => messages['validations.maxLength'],
      withArguments: true,
    }),
    minValue: withI18nMessage(validators.minValue(1), {
      messagePath: () => messages['validations.minValue'],
      withArguments: true,
    }),
    maxValue: withI18nMessage(validators.maxValue(9999), {
      messagePath: () => messages['validations.maxValue'],
      withArguments: true,
    }),
    numeric: withI18nMessage(validators.numeric, {
      messagePath: () => messages['validations.numeric'],
      withArguments: true,
    }),
  }
}
